import React from 'react';

export const EmailShowCase = () => {
  return (
    <div className="relative  mt-24 px-4 md:px-0" >

    <div className="blurred-class relative max-w-screen z-0 ">
          <img alt="" className='absolute -top-20 transform  left-2 scale-100' src="/images/blurred_1.png" />
          <img alt="" className='absolute left-20 md:ml-64 -top-10 transform scale-50 md:scale-125' src="/images/blurred_2.png" />
          {/* <img className='absolute right-32 transform scale-100' src="/images/blurred_1.png" /> */}
          <img alt="" className='absolute right-5 transform  md:scale-150' src="/images/blurred_2.png" />
    </div>

    <div style={{ height:'440px' }} className="z-20 relative max-w-6xl mx-auto overflow-hidden rounded-3xl bg-black bg-opacity-70 glassbox pt-8 md:pt-12  px-2 md:px-10 shadow-lg ">
      <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
        <div className="grid grid-cols-1 gap-6">
          <img  alt="emails designed using emailkick" src="/images/email-design-001.png"  className="w-full shadow-lg rounded-xl" />
        </div>

        <div className="grid grid-cols-1 gap-6">
          <img alt="emails designed using emailkick" src="/images/email-design-1.svg"   className="w-full  shadow-lg rounded-xl" />
          <img  alt="emails designed using emailkick" src="/images/email-design-2.svg"   className="w-full  shadow-lg rounded-xl" />

        </div>

        <div className="grid grid-cols-1 gap-6">
          <img  alt="emails designed using emailkick" src="/images/email-design-3.svg"   className="w-full  shadow-lg rounded-xl" />
          <img  alt="emails designed using emailkick" src="/images/email-design-4.svg"  className="w-full  shadow-lg rounded-xl" />

        </div>

        <div>
          <img src="/images/email-design-4.svg"  className="w-full  shadow-lg rounded-xl block md:hidden" />

        </div>
      </div>
    </div>
    
  </div>);
};
